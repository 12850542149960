<script setup lang="ts">
import { useLangStore } from '~/stores/lang-store';
// ---------------------------------------
const router = useRouter();
const langStore = useLangStore();
const t = langStore.t;
// ---------------------------------------
</script>
<template>
  <div>
    <div class="locale">
      <v-btn variant="outlined" color="white" @click="langStore.toggleLang()">
        {{ t('com.langtoggle') }}
      </v-btn>
    </div>
    <header>
      <v-container class="pa-0 h-100">
        <nav class="logo h-100">
          <img
            src="/assets/img/header-logo.svg"
            alt="Logo"
            @click="router.push({ path: `/${langStore.state.lang}/` })"
          />
        </nav>
      </v-container>
    </header>
    <slot />
    <div class="COPYRIGHT">COPYRIGHT ©2023 Hare-Systems</div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
header {
  border-bottom: 1px solid white;
  background-color: #ff9100;
  height: 60px;
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    height: 50px;
  }
  .logo {
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
      justify-content: start;
      width: 60%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
.locale {
  z-index: 1;
  position: absolute;
  inset: 0 10px 0 auto;
  width: 12em;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
    width: 8em;
    font-size: 12px;
  }

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    height: 50px;
  }
  > * {
    width: 100%;
  }
}
.COPYRIGHT {
  position: fixed;
  inset: auto 0 0 auto;
  display: flex;
  justify-content: space-between;
  color: rgba(93, 93, 93, 0.664);
  font-size: 12px;
  line-height: 1em;
  padding: 0 2px 2px 0;

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    flex-direction: column;
    align-items: center;
  }

  > div {
    font-size: 12px;
    padding: 2px;
  }
}
</style>
